import { Slot } from '@radix-ui/react-slot';
import { cva, type VariantProps } from 'class-variance-authority';
import * as React from 'react';

import { cn } from '~/lib/utils';

// NOTE: Alangkah baiknya jika menggunakan svg, sematkan dalam tag img (ambil dari public) karena tidak ada bg-clip untuk svg :)

const buttonVariants = cva(
  'font-ubuntu peer w-full inline-flex px-4 py-2 md:px-5 md:py-3 max-md:text-s6 md:text-s5 items-center font-bold justify-center gap-2 whitespace-nowrap rounded-[8px] md:rounded-2xl ring-offset-white transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-neutral-950 focus-visible:ring-offset-2 disabled:pointer-events-none  [&_svg]:pointer-events-none [&_svg]:size-6 [&_svg]:shrink-0 dark:ring-offset-neutral-950 dark:focus-visible:ring-neutral-300',
  {
    variants: {
      variant: {
        primary:
          'bg-color-gradient-light bg-blend-overlay text-neutral-50 hover:bg-black/20 active:bg-black/40 disabled:bg-black/60 disabled:text-cosmic-jade-light-500 dark:bg-color-gradient-dark',
        secondary:
          'bg-cosmic-jade-light-100 text-cosmic-jade-light-700 hover:bg-cosmic-jade-light-300 active:bg-cosmic-jade-light-500 active:text-cosmic-jade-light-900 disabled:bg-cosmic-jade-light-700 disabled:text-cosmic-jade-light-1000 dark:bg-cosmic-jade-dark-100 dark:text-cosmic-jade-dark-700 hover:dark:bg-cosmic-jade-dark-300 dark:active:bg-cosmic-jade-dark-500 dark:active:text-cosmic-jade-dark-900 dark:disabled:bg-cosmic-jade-dark-700 dark:disabled:text-cosmic-jade-dark-1000',
        ghost:
          'bg-color-gradient-light dark:bg-color-gradient-dark bg-clip-text text-transparent disabled:text-neutral-300 z-10',
        tertiary:
          'bg-blend-overlay bg-color-gradient-light dark:bg-color-gradient-dark bg-clip-text text-transparent hover:bg-color-hover-light active:bg-color-pressed-light dark:hover:bg-color-hover-dark dark:active:bg-color-pressed-dark disabled:text-neutral-300 z-10',
      },
    },
    defaultVariants: {
      variant: 'primary',
    },
  }
);

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {
  borderClassName?: string;
  asChild?: boolean;
  grid?: string;
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      borderClassName,
      className,
      variant,
      asChild = false,
      grid = '',
      disabled,
      ...props
    },
    ref
  ) => {
    const Comp = asChild ? Slot : 'button';
    return (
      <div
        className={cn(
          'flex relative',
          grid,
          variant === 'ghost'
            ? 'rounded-[8px] md:rounded-2xl bg-color-gradient-light dark:bg-color-gradient-dark p-[1px]'
            : ''
        )}
      >
        {variant === 'ghost' ? (
          <div className="w-full flex items-center justify-center bg-white dark:bg-black rounded-[7px] md:rounded-[11px] hover:bg-cosmic-jade-light-050 hover:dark:bg-cosmic-jade-light-050 duration-300">
            <Comp
              ref={ref}
              className={cn(
                buttonVariants({ variant }),
                borderClassName,
                className
              )}
              disabled={disabled}
              {...props}
            />
          </div>
        ) : (
          <Comp
            ref={ref}
            className={cn(
              buttonVariants({ variant }),
              borderClassName,
              className
            )}
            disabled={disabled}
            {...props}
          />
        )}
      </div>
    );
  }
);
Button.displayName = 'Button';

export { Button, buttonVariants };
